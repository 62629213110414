/* Import normalize */
@import url(normalize.css); 

/* Adding variables */
:root{
    --max-width: 800px;
    --primary-color: #015fc3;
    --border-color: #cccccc;
    --warning-color:#c82333;
    --succes-color: #28a745;
}

/* Setting font-family */
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5;
}

h2, h3, p{
    padding: 0;
    margin: 0;
}

.text{
    max-width: 70ch;
    margin-bottom: 1rem;
}

/* Containers */
.container--l{
    max-width: var(--max-width);
    margin-inline: auto;
    padding-inline: 2dvw;
}

/* Button styles */
.button, button {
    display: inline-block;
    padding: 0.7rem 1.4rem;
    border: 1px solid transparent;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color:#e6e6e6;
    border-radius: 6px;
    text-decoration: none;
}

.primary{
    background-color: var(--primary-color);
    color: #fff;
}

.outlined{
    background-color: transparent;
    border: 1px solid black;
    color: black;
}

.delete{
    background-color: var(--warning-color);
    display: flex;
    color:white;
    padding: .6rem 1.2rem;

}

.add{
    background-color: var(--succes-color);
    color: white;
}


/* Form styles */
form {
    max-width: 400px;
    margin-block: 1rem;
}

.form__group {
    margin-block-end: 1.5rem;
  }

label {
    display: block;
    font-weight: bold;
    margin-block-end: 0.2rem;
}

input[type="text"],
select, input[type="date"], textarea {
    width: 100%;
    max-width: 90dvw;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
}

textarea{
    min-height: 150px;
}


#jobList{
    list-style: none;
    padding: 0;
}

#jobList li {
    display: block;
    margin-block: 1rem;
    background-color: #f0f0f0;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
}


.job__details{
    font-size: 0.9rem;
    color:#333333;    
}

.job_description{
    margin-block-start: 0.5rem;
    margin-block-end:1rem;
}

.job__actions{
    display: flex;
    gap: 1rem;
    position:relative;
    margin-block-start: 1rem;
}


/* Navigation */
header{
    background-color: #f0f0f0;
}
nav {
    display: flex;
    justify-content: space-between;
    
 
  }
  
  nav ul {
    display: flex; 
    list-style: none; 
    padding:0;
    gap: 1rem;
  }

  nav a {
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
  }

.active{
    font-weight: 700;
}
  